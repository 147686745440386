<template>
  <div class="app-page">
    <div class="header">
      <div>
        <span>区域名称</span>
        <el-input v-model="areaName"
          class="w_200"
          placeholder="请输入区域名称" />
        <el-button type="primary"
          @click="getData">搜 索</el-button>
      </div>
      <el-button type="primary"
        @click="handleRule(null)">增加区域规则</el-button>
    </div>
    <div class="content">
      <div class="content-left">
        <el-table :data="tableData"
          size="small"
          height="100%"
          border
          style="width: 100%"
          @cell-click="handleClick">
          <el-table-column align="center"
            type="index"
            label="序号"
            width="50" />
          <el-table-column align="center"
            prop="pathName"
            label="区域名称"
            width="120" />
          <el-table-column align="center"
            prop="ruleType"
            label="规则类型"
            width="120" />
          <el-table-column align="center"
            prop="address"
            label="操作"
            v-slot="{ row }">
            <div class="oper">
              <span class="binding"
                @click.stop="handleBinding(row)">绑车</span>
              <span class="edit"
                @click.stop="handleRule(row)">修改</span>
              <span class="del"
                @click.stop="handleDel(row)">删除</span>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-right">
        <AMap ref="mapRef"
          :center="mapCenter"
          :zoom="8">
          <!-- 圆形 -->
          <MapCircle ref="circleRef"
            v-if="pathType===41"
            :isVisible="pathType===41"
            :center="circleProps.center"
            :radius="circleProps.radius"
            autoFitView></MapCircle>
          <!-- 矩形 -->
          <MapRectangle ref="rectangleRef"
            v-if="pathType===43"
            :isVisible="pathType===43"
            :bounds="rectangleProps.bounds"
            autoFitView></MapRectangle>

          <!-- 多边形 -->
          <MapPolygon ref="polygonRef"
            v-if="pathType===44"
            :isVisible="pathType===44"
            :path="polygonProps.path"
            autoFitView></MapPolygon>
        </AMap>
      </div>
    </div>

    <AddAreaRule @on-ok="getData"
      @handleSuccess="getData"
      v-model="editModal"
      :iProp="editRow" />

    <bind-vehicle v-model="bindModal"
      @on-bind="bindChange"
      :isFilter="false"
      :showCheckbox="true"
      :checkData="visibleBinds"></bind-vehicle>
  </div>
</template>

<script>
import AMap, { MapPolygon, MapRectangle, MapCircle } from "@/components/AMap";
import AddAreaRule from "./AddAreaRule.vue";
import { getAreaRules, deletePathRule, getPointMsg, bindVehiclePath, getVehicleInfoByPathId } from '@/api/getRule.js';
import BindVehicle from "@/components/BindVehicle";
import { getTreeNodeId, TreeNodeType } from '@/utils/treeHelper.js';
import GPS from '@/utils/address';

export default {
  // `name`与路由保持一致
  name: 'AreaConfiguration',
  components: {
    AMap,
    MapPolygon,
    MapCircle,
    MapRectangle,
    AddAreaRule,
    BindVehicle
  },
  data() {
    return {
      tableData: [], // 规则list
      areaName: '', // 搜索输入字符串
      editRow: {}, // 修改数据
      editModal: false, // 编辑显示modal
      pathType: 0, // 区域类型
      autoId: null, // 规则id
      type: '3,14,5', //规则类型 3：禁止驶入驶出，14：区域限速，
      bindModal: false,// 绑定车辆modal
      visibleBinds: [], // 已经绑定的数据
      circleProps: { // 圆形区域数据
        center: [],
        radius: 0,
        editIsOpen: false,
      },
      rectangleProps: { // 矩形区域数据
        bounds: [],
        editIsOpen: false,
      },
      polygonProps: {// 多边形区域数据
        path: [],
        editIsOpen: false,
      },

      mapCenter: [116.482169, 39.998932],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //查询数据
    async getData() {
      const result = await getAreaRules({ name: this.areaName, type: this.type });
      if (result.flag !== 1 && result.rows.length) return this.$message.warning(result.msg);
      const tableDatas = JSON.parse(JSON.stringify(result.rows));
      tableDatas.map((item, index) => {
        if (item.pathType == 5) {
          item.pathType = 3;
        }
      });
      this.tableData = tableDatas;
    },

    //点击table行 根据id查询绑定区域
    async handleClick(row) {
      const result = await getPointMsg({ pathId: row.pathId });
      if (!result.flag) return;

      const { pointList } = result.obj;
      if (!pointList.length) return this.$message.warning("暂无区域数据");

      this.drawAreaChange(result.obj); // 绘制对应区域
    },

    // 显示区域
    drawAreaChange(node) {
      this.pathType = node.pathType;

      const points = node.pointList.map(item => {
        const point = GPS.delta(item.oriLat, item.oriLon);
        return {
          ...item,
          oriLat: point.lat,
          oriLon: point.lon
        };
      });
      switch (node.pathType) {
        case 41: // 绘制圆形区域
          const params = points[0]; // 绘制区域
          this.circleProps.center = [params.oriLon, params.oriLat];
          this.circleProps.radius = params.radius;

          break;
        case 43: // 绘制矩形区域
          this.rectangleProps.bounds = points.map(point => ([point.oriLon, point.oriLat]));

          break;
        case 44:// 绘制多边形区域
          this.polygonProps.path = points.map(point => ([point.oriLon, point.oriLat]));

          break;
        default:
      }
    },

    //显示规则弹框
    handleRule(row) {
      this.editModal = true;
      if (!row) return this.editRow = {};
      this.editRow = row;
    },

    //点击绑定车辆
    handleBinding(row) {
      this.autoId = row.autoId;
      this.getVehicleInfoId();
    },

    //根据规则id查询绑定数据
    async getVehicleInfoId() {
      const result = await getVehicleInfoByPathId({ autoId: this.autoId });
      this.bindModal = true;
      if (!result.flag) return this.$message.warning(result.msg);

      let { groupList, vehicleList } = result.obj;

      groupList = groupList.map(item => getTreeNodeId(item.groupId));

      vehicleList = vehicleList.map(item => getTreeNodeId(item.groupId, item.vehicleId));
      this.visibleBinds = [...groupList, ...vehicleList];
    },

    // 绑定事件
    async bindChange({ vehicles, groups }) {
      const params = {
        pathId: this.autoId,
        gids: groups.map(item => item.groupId).join(','),
        vids: vehicles.map(item => item.V).join(',')
      };

      const result = await bindVehiclePath(params);
      if (!result.flag) return this.$message.warning(result.msg);

      this.bindModal = false;
      this.$message.success("绑定成功");
    },

    // 删除区域规则
    async handleDel(row) {
      const { autoId } = row;

      const cfm = await this.$confirm('此操作将永久删除该规则, 是否继续?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => null);

      if (cfm !== 'confirm') return;

      const result = await deletePathRule({ autoId: autoId });

      if (!result.flag) return this.$message.warning(result.msg);
      this.$message.success("删除成功");
      this.getData();
    },
  }
}
</script>
<style lang="scss" scoped>
.app-page {
  height: 100%;
  width: 100%;
  background: #fbfafa;
  div {
    box-sizing: border-box;
  }
  ::v-deep .el-table th.el-table__cell {
    background: #f5f5f5 !important;
  }
  .oper {
    > span {
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;
    }
    .binding {
      color: #1890ff;
    }
    .edit {
      color: #1890ff;
    }
    .del {
      color: #de2b2b;
    }
  }
  .header {
    padding: 20px;
    height: 70px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    color: #262626;
    .w_200 {
      width: 200px;
      margin: 0 10px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
    height: calc(100% - 70px);
    &-left {
      width: 30%;
      height: 100%;
      border: 1px solid #d4d4d4;
    }
    &-right {
      width: calc(70% - 20px);
      border: 1px solid #d4d4d4;
      height: 100%;
      background: #fff;
    }
  }
}
</style>