<template>
  <el-dialog v-if="modal"
    :visible="modal"
    width="800px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    @close="onModalChange">
    <template #title>
      <div class="header">
        <span>增加区域规则</span>
      </div>
    </template>
    <div class="content">
      <div class="content__item">
        <label>区域选择</label>
        <el-select v-model="editParams.pathId"
          placeholder="请选择"
          class="w_100">
          <el-option v-for="item in areaList"
            :key="item.pathId"
            :label="item.pathName"
            :value="item.pathId"></el-option>
        </el-select>
      </div>

      <div class="content__item">
        <label>规则名称</label>
        <el-input v-model="editParams.pathName"
          placeholder="请输入规则名称" />
      </div>

      <div class="content__item">
        <label>规则类型</label>
        <el-select v-model="editParams.pathType"
          placeholder="请选择"
          class="w_100">
          <el-option v-for="item in getOptions()"
            :key="item.value"
            :label="item.label"
            :value="item.value">{{item.label}}</el-option>
        </el-select>
      </div>

      <div class="content__item"
        v-if="[3].includes(editParams.pathType)">
        <label>{{['驶出时长','驶入时长'][editParams.param]}}</label>
        <el-input-number style="width:52%"
          v-model="editParams.settingTime"
          :step="1"
          :min="1"
          label="描述文字"></el-input-number>(分)
      </div>

      <div class="content__item fw100"
        v-if="[3].includes(editParams.pathType)">
        <el-radio style="margin-left:115px"
          v-model="editParams.param"
          size="mini"
          v-for="item in getOptions(3,-1)"
          :key="item.value"
          :label="item.value">{{item.label}}</el-radio>
      </div>

      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>持续时间（秒）</label>
        <el-input-number style="width:62%"
          v-model="paramtext.duration"
          size="mini"
          :step="1"
          :min="0"></el-input-number>
      </div>

      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>开始时间</label>
        <el-time-picker v-model="startTime"
          placeholder="开始时间"
          style="width:220px"></el-time-picker>
      </div>
      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>结束时间</label>
        <el-time-picker v-model="endTime"
          placeholder="结束时间"
          style="width:220px"></el-time-picker>
      </div>

      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>限速比例（%）</label>
        <el-input-number style="width:62%"
          v-model="paramtext.timeRangeRatio"
          size="mini"
          :step="1"
          :min="0"
          :max="100"></el-input-number>
      </div>
      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>区域外限速值(Km/h)</label>
        <el-input-number style="width:62%"
          v-model="paramtext.outSpeedLimit"
          size="mini"
          :step="1"
          :min="0"></el-input-number>
      </div>

      <div v-if="[14].includes(editParams.pathType)"
        class="content__item">
        <label>区域内限速阈值(Km/h)</label>
        <el-input-number style="width:62%"
          v-model="editParams.maxSpeed"
          size="mini"
          :step="1"
          :min="0"></el-input-number>
      </div>

      <div class="content__item fw100">
        <el-checkbox v-model="editParams.displayUsage"
          :true-label="8"
          :false-label="0">启用TTS播报</el-checkbox>
        <el-input v-model="editParams.text"
          size="mini"
          type="textarea"
          :rows="3"
          :disabled="[0].includes(editParams.displayUsage)"
          placeholder="请输入播放内容"></el-input>
      </div>

      <div class="content__collapse"
        v-if="[3].includes(editParams.pathType)">
        <el-collapse>
          <el-collapse-item Consistency
            name="1">
            <template slot="title">
              <el-checkbox v-model="editParams.videotype"
                :true-label="1"
                :false-label="0">拍照参数</el-checkbox>
            </template>
            <PhotoParam v-model="videoTranRule"></PhotoParam>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <el-button @click="onModalChange(false)">取 消</el-button>
        <el-button type="primary"
          :loading="isLoading"
          @click="handleSave">保 存</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { getTreeArea, addPathRule, getPathRuleInfobyId, updatePathRule } from '@/api/getRule.js';
import PhotoParam from '@/components/PhotoParamCard';
import moment from 'dayjs';

export default {
  name: "AddAreaRule",
  components: {
    PhotoParam
  },
  model: {
    prop: 'modal',
    event: 'modal-change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    iProp: { // 传入要编辑的数据
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      types: [// 规则类型
        { label: '禁止驶入驶出', value: 3 },
        { label: '区域限速', value: 14 },
      ],
      areaType: null,
      ruleName: '',
      type: '41,43,44', //查区域
      areaList: [], // 区域list
      videoTranRule: {}, //拍照参数
      paramtext: {
        duration: 0, //持续时间（秒）不能为空并且只能在0-10000之间
        timeRangeRatio: 0, //限速比例（%）不能为空并且只能在0-100之间
        outSpeedLimit: 0, //区域外限速值(Km/h)不能为空并且只能在0-300之间
        timeRange: '', //区域限速时间
      },
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      editParams: this.initParams(),
      isLoading: false
    };
  },
  watch: {
    modal: {
      // immediate: true,
      async handler(val) {
        if (val) {
          this.editParams = this.initParams(); // 初始化输入框
          this.startTime = new Date(0, 0, 0);
          this.endTime = new Date(0, 0, 0);
          this.getAreaList();  // 获取区域列表

          const { autoId } = this.iProp;

          if (autoId) return this.getPathRuleInfo(autoId);
        }
      }
    },
  },
  methods: {
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', !!modal);
      this.$emit(eventName, ...options);
    },
    /**
     * 区域类型
     * @param {*} type 区域类型 -1: 所有选项 
     * @param {*} param 区域子类型 -1: 子类型所有选项 , null: 过滤掉子类型
    */
    getOptions(type = -1, param = null) {

      const types = [
        { label: '禁止驶入驶出', value: 3 },
        { label: '区域限速', value: 14 }
      ];

      if ([3, 4].includes(type) && param !== null) {
        //禁止驶入驶出 选项
        const opts1 = [
          { label: '禁止驶出区域', value: 0, type: 3 },
          { label: '禁止驶入区域', value: 1, type: 3 }
        ];
        //非法卸料报警 选项
        const opts2 = [
          { label: '区域外正转报警', value: 0, type: 4 },
          { label: '区域外反转报警', value: 1, type: 4 },
        ];

        return [
          ...opts1,
          ...opts2
        ].filter(p => [p.type].includes(type) && [-1, p.value].includes(param));
      }

      return types.filter(p => [-1, p.value].includes(type));
    },


    // 获取区域列表
    async getAreaList() {
      const result = await getTreeArea({ type: this.type, });

      if (result.flag !== 1) return this.$message.warning(result.msg);

      this.areaList = result.rows;
    },

    // 根据区域id查询区域
    async getPathRuleInfo(autoId) {
      const result = await getPathRuleInfobyId({ autoId: autoId });
      if (!result.flag) return this.$message.warning(result.msg);
      if (result.obj.pathType == 5) {
        result.obj.pathType = 3;
      }
      this.editParams = result.obj;
      const { pathType, videotype } = result.obj;

      if ([14].includes(pathType)) {
        this.paramtext = JSON.parse(result.obj.paramtext);
        const times = this.paramtext.timeRange.split('-').map(s => new Date(0, 0, 0, ...s.split(':')));
        this.startTime = times[0];
        this.endTime = times[1];
      }

      if (videotype === 1) {
        const data = result.obj.videoTranRule;
        this.videoTranRule = {
          channel: data.cameraline, //通道ID
          shootCmd: data.type, //拍摄命令
          saveFlag: data.uploadFlag, //保存标志
          dpi: data.disRate, //分辨率
          quality: data.pictureQuality, //图片/视频 质量
          luminance: data.brightness,  //亮度
          contrast: data.contrastRatio,  //对比度
          saturability: data.saturation,  //饱和度
          chroma: data.chroma, //色度
        };
      }
    },

    // 初始化数据
    initParams() {
      return {
        pathId: '', //区域id
        pathName: '',// 规则名称
        pathType: 3, // 规则类型
        param: 0, //禁止驶出区域时为：0，禁止驶入区域时为：1
        displayUsage: 8,//报警播报方式（启动TTS播报：8，不启用：0，默认不启用）
        text: "", //播报内容（启用播报此值必填）
        settingTime: 0, // 驶入时长
        videotype: 0,//是否启动 拍照/视频（0表示不开启，1表示开启）
        videoTranRule: {}, //拍照参数
        maxSpeed: 0,//区域内限速阈值
        paramtext: {},
      };
    },

    //关闭弹框
    handleClose() {
      this.editParams = this.initParams();
    },

    //是否展示TTS播报
    hasTTS(pathType) {
      return ![
        6, //异常停留报警
        26, //非法区域上下客
        25, //非法排放区域
        28, //倾倒区域
      ].includes(pathType);
    },

    // 校验数据
    validateData() {
      const { pathId, pathName, pathType, param, displayUsage, text, settingTime, maxSpeed } = this.editParams;
      if (!pathId) throw '请选择区域';
      if (!pathName) throw '请输入区域名称';
      if ([3].includes(pathType) && ![0, 1].includes(param)) throw '请选择禁止驶入区域或者禁止驶出区域';
      if ([3].includes(pathType) && settingTime === '') throw `请输入${ ['驶出时长', '驶入时长'][param] }`;
      if ([14].includes(pathType) && this.paramtext.duration === '') throw '请输入持续时间';
      if ([14].includes(pathType) && maxSpeed === '') throw '请输入区域内限速阈值';
      if ([14].includes(pathType) && this.startTime === '') throw '请输入开始时间';
      if ([14].includes(pathType) && this.endTime === '') throw '请输入结束时间';
      if ([14].includes(pathType) && new Date(this.startTime).getTime() > new Date(this.endTime).getTime()) throw '开始时间不能大于结束时间';
      if ([14].includes(pathType) && this.paramtext.timeRangeRatio === '') throw '请输入限速比例（%）';
      if ([14].includes(pathType) && this.paramtext.outSpeedLimit === '') throw '请输入区域外限速值';

      if (this.hasTTS(pathType) && [8].includes(displayUsage) && !text) throw '请输入播放内容';
    },

    //保存按钮
    async handleSave() {
      try {
        this.validateData();
      } catch (err) {
        return this.$message.warning(err);
      }

      const { autoId } = this.iProp;

      let action = autoId ? updatePathRule : addPathRule;
      if ([14].includes(this.editParams.pathType)) this.paramtext.timeRange = `${ moment(new Date(this.startTime)).format("HH:mm:ss") }-${ moment(new Date(this.endTime)).format("HH:mm:ss") }`;

      if ([3].includes(this.editParams.pathType)) {
        this.videoTranRule = {
          type: this.editParams.videotype === 1 ? this.videoTranRule.shootCmd : 0,
          cameraline: this.videoTranRule.channel,
          disRate: this.videoTranRule.dpi,
          uploadFlag: this.videoTranRule.saveFlag,
          pictureQuality: this.videoTranRule.quality,
          brightness: this.videoTranRule.luminance,
          contrastRatio: this.videoTranRule.contrast,
          saturation: this.videoTranRule.saturability,
          chroma: this.videoTranRule.chroma,
        };
      }
      this.isLoading = true;
      const editParams = JSON.parse(JSON.stringify(this.editParams));

      if (this.editParams.param == 1) {
        editParams.pathType = 5;
      }
      const result = await action({
        ...editParams,
        paramtext: this.paramtext,
        videoTranRule: this.videoTranRule
      });
      this.isLoading = false;

      if (!result.flag) return this.$message.warning(result.msg);
      if (result.flag) this.$message.success(result.msg);

      this.$emit('on-ok');
      this.onModalChange(false);

    },

  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn {
  top: 10px;
  i {
    color: #fff;
    font-weight: 600;
  }
}
::v-deep .el-dialog__header {
  padding: 10px 15px;
  background: #212d3e;
  color: #fff;
}
::v-deep .el-dialog__body {
  padding: 0;
}

.content {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;
  overflow: auto;
  &__item {
    width: 50%;
    display: flex;
    padding: 12px;
    line-height: 30px;
    & > label {
      display: block;
      width: 160px;
      text-align: right;
      font-size: 14px;
      margin: 0 15px 0 0;
    }
    .el-input {
      width: 220px;
    }
  }
  &__collapse {
    width: 92%;
    margin: 30px auto 0;
    border-top: 1px dashed #d5d5d5;
  }
  .fw100 {
    width: 100%;
    & > label {
      width: auto;
    }
    .el-textarea {
      width: 82%;
      margin: 0 0 0 10px;
    }
  }
}

::v-deep .el-collapse {
  border: none !important;
}

::v-deep .el-collapse-item__header {
  border: none;
}
</style>